import { menu } from "../actionTypes";

const initialState = {
  tableItems: [],
  tokenObject: {},
  menuIDs: [],
  id: undefined,
  name: undefined,
  description: undefined,
  menuInfo: {},

  createMenuToggle: false,
  updateMenuToggle: false,
  deleteMenuToggle: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case menu.LIST_MENU: {
      const { tableItems, menuIDs, tokenObject, token } = action.payload;
      var newTableItems = tableItems;
      if (token != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return { ...state, tableItems: newTableItems, menuIDs, tokenObject };
    }
    case menu.GET_MENU: {
      const { name, description } = action.payload;

      var menuInfo = {
        name,
        description
      };

      return { ...state, menuInfo };
    }
    case menu.CREATE_MENU: {
      const { result } = action.payload;

      var newTableItems = [
        {
          id: result.id,
          name: result.name,
          description: result.description
        },
        ...state.tableItems
      ];
      return { ...state, tableItems: newTableItems };
    }
    case menu.UPDATE_MENU: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.id;
      });
      newTableItems[index] = {
        id: result.id,
        name: result.name,
        description: result.description
      };
      var menuInfo = {
        name: result.name,
        description: result.description
      }
      return { ...state, tableItems: newTableItems, menuInfo };
    }
    case menu.DELETE_MENU: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.id;
      });
      newTableItems.splice(index, 1);

      return { ...state, tableItems: newTableItems };
    }
    case menu.TOGGLE_CREATE_MENU: {
      const { toggle } = action.payload;
      return {
        ...state,
        createMenuToggle: toggle ? !state.createMenuToggle : false
      };
    }
    case menu.TOGGLE_UPDATE_MENU: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateMenuToggle: toggle ? !state.updateMenuToggle : false
      };
    }
    case menu.TOGGLE_DELETE_MENU: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteMenuToggle: toggle ? !state.deleteMenuToggle : false
      };
    }
    case menu.RESET_MENU_PARAMETERES: {
      return {
        ...state,
        id: undefined,
        name: undefined,
        description: undefined
      };
    }
    case menu.UPDATE_MENU_PARAMETERS: {
      const { id, name, description } = action.payload;
      return { ...state, id, name, description };
    }
    case menu.DELETE_MENU_PARAMETERS: {
      const { id } = action.payload;
      return { ...state, id };
    }
    case menu.HANDLE_CHANGE_MENU: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
