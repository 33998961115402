export const LIST_ITEM = "LIST_ITEM";
export const CREATE_ITEM = "CREATE_ITEM";
export const UPDATE_ITEM = "UPDATE_ITEM";
export const DELETE_ITEM = "DELETE_ITEM";

export const TOGGLE_CREATE_ITEM = "TOGGLE_CREATE_ITEM";
export const TOGGLE_UPDATE_ITEM = "TOGGLE_UPDATE_ITEM";
export const TOGGLE_DELETE_ITEM = "TOGGLE_DELETE_ITEM";

export const RESET_ITEM_PARAMETERES = "RESET_ITEM_PARAMETERES";
export const UPDATE_ITEM_PARAMETERS = "UPDATE_ITEM_PARAMETERS";
export const UPDATE_SINGLE_ITEM_PARAMETER = "UPDATE_SINGLE_ITEM_PARAMETER";
export const DELETE_ITEM_PARAMETERS = "DELETE_ITEM_PARAMETERS";

export const HANDLE_CHANGE_ITEM = "HANDLE_CHANGE_ITEM";
export const HANDLE_CHANGE_ITEM_CHOICE = "HANDLE_CHANGE_ITEM_CHOICE"
export const HANDLE_CHANGE_ITEM_OPTION = "HANDLE_CHANGE_ITEM_OPTION"
export const HANDLE_CHANGE_ITEM_TYPE = "HANDLE_CHANGE_ITEM_TYPE"
