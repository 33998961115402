import { reward } from "../actionTypes";
import { graphQLOutputDateFormat } from "../../helper";

const initialState = {
  tableItems: [],
  tokenObject: {},
  title: undefined,
  picture: undefined,
  startDate: undefined,
  endDate: undefined,
  description: undefined,
  amountRequired: undefined,

  restaurantID: "allRestaurant",
  restaurantName: "All Restaurant",

  restaurantOptions: "",

  createRewardToggle: false,
  updateRewardToggle: false,
  deleteRewardToggle: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case reward.LIST_REWARD: {
      const { tableItems, tokenObject, token, search } = action.payload;
      var newTableItems = tableItems;
      if (token != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return { ...state, tableItems: newTableItems, tokenObject, search };
    }
    case reward.CREATE_REWARD: {
      const { result } = action.payload;

      var startDate = graphQLOutputDateFormat(result.startDate);
      var endDate = graphQLOutputDateFormat(result.endDate);

      var newTableItems = [
        {
          id: result.id,
          title: result.title,
          picture: result.picture,
          startDate,
          endDate,
          description: result.description,
          amountRequired: result.amountRequired,
          restaurantName: result.restaurant.name
        },
        ...state.tableItems
      ];
      return { ...state, tableItems: newTableItems };
    }
    case reward.UPDATE_REWARD: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.id;
      });
      var startDate = graphQLOutputDateFormat(result.startDate);
      var endDate = graphQLOutputDateFormat(result.endDate);

      newTableItems[index] = {
        id: result.id,
        title: result.title,
        picture: result.picture,
        startDate,
        endDate,
        description: result.description,
        amountRequired: result.amountRequired,
        restaurantName: result.restaurant.name
      };
      return { ...state, tableItems: newTableItems };
    }
    case reward.DELETE_REWARD: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.id;
      });
      newTableItems.splice(index, 1);

      return { ...state, tableItems: newTableItems };
    }
    case reward.TOGGLE_CREATE_REWARD: {
      const { toggle } = action.payload;
      return {
        ...state,
        createRewardToggle: toggle ? !state.createRewardToggle : false
      };
    }
    case reward.TOGGLE_UPDATE_REWARD: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateRewardToggle: toggle ? !state.updateRewardToggle : false
      };
    }
    case reward.TOGGLE_DELETE_REWARD: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteRewardToggle: toggle ? !state.deleteRewardToggle : false
      };
    }
    case reward.RESET_REWARD_PARAMETERES: {
      return {
        ...state,
        id: undefined,
        title: undefined,
        picture: undefined,
        startDate: undefined,
        endDate: undefined,
        description: undefined,
        amountRequired: undefined
      };
    }
    case reward.UPDATE_REWARD_PARAMETERS: {
      const {
        id,
        title,
        picture,
        startDate,
        endDate,
        description,
        amountRequired,
        rewardRestaurantID
      } = action.payload;
      return {
        ...state,
        id,
        title,
        picture,
        startDate,
        endDate,
        description,
        amountRequired,
        rewardRestaurantID
      };
    }
    case reward.DELETE_REWARD_PARAMETERS: {
      const { id, rewardRestaurantID } = action.payload;
      return { ...state, id, rewardRestaurantID };
    }
    case reward.PROCESS_OWNER_RESTAURANT_OPTIONS: {
      const { restaurantOptions } = action.payload;
      return {
        ...state,
        restaurantOptions
      };
    }
    case reward.HANDLE_CHANGE_REWARD: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    case reward.HANDLE_CHANGE_REWARD_RESTAURANT: {
      const { rewardRestaurantId } = action.payload;
      return { ...state, rewardRestaurantId };
    }
    case reward.HANDLE_CHANGE_CHOOSE_REWARD_OWNER_RESTAURANT: {
      const { restaurantID, restaurantName } = action.payload;
      return { ...state, restaurantID, restaurantName };
    }
    default:
      return state;
  }
};
