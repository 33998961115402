import { combineReducers } from "redux";
import helper from "./helper";
import owner from "./owner";
import restaurant from "./restaurant";
import menu from "./menu";
import item from "./item"
import customer from "./customer";
import order from "./order";
import table from "./table";
import user from "./user";
import reward from "./reward";
import rewardCode from "./rewardCode"

export default combineReducers({
  helper,
  owner,
  restaurant,
  menu,
  item,
  customer,
  order,
  table,
  user,
  reward,
  rewardCode
});
