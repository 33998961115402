export const LIST_MENU = "LIST_MENU";
export const GET_MENU = "GET_MENU";
export const CREATE_MENU = "CREATE_MENU";
export const UPDATE_MENU = "UPDATE_MENU";
export const DELETE_MENU = "DELETE_MENU";

export const TOGGLE_CREATE_MENU = "TOGGLE_CREATE_MENU";
export const TOGGLE_UPDATE_MENU = "TOGGLE_UPDATE_MENU";
export const TOGGLE_DELETE_MENU = "TOGGLE_DELETE_MENU";

export const RESET_MENU_PARAMETERES = "RESET_MENU_PARAMETERES";
export const UPDATE_MENU_PARAMETERS = "UPDATE_MENU_PARAMETERS";
export const DELETE_MENU_PARAMETERS = "DELETE_MENU_PARAMETERS";

export const HANDLE_CHANGE_MENU = "HANDLE_CHANGE_MENU"
