import { user } from "../actionTypes";

const initialState = {
  userType: null,
  gotoHome: false,
  gotoLogin: false,
  gotoResetPass: false,
  gotoChangePass: false,
  gotoNewPass: false,
  loginNewPassword: false,
  newPasswordToggle: false,
  forgotPasswordToggle: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case user.LOGIN: {
      return { ...state, gotoHome: true };
    }
    case user.FETCH_USER: {
      const { type, id } = action.payload;
      return { ...state, userType: type, ownerID: id };
    }
    case user.LOGOUT: {
      const logoutState = {
        userType: null,
        gotoHome: false,
        gotoLogin: true,
        gotoResetPass: false,
        gotoChangePass: false,
        gotoNewPass: false
      };
      return { ...state, ...logoutState };
    }
    case user.RESET_PASSWORD: {
      return state;
    }
    case user.GOTO_LOGIN: {
      return { ...state, gotoLogin: true };
    }
    case user.LOGIN_NEW_PASSWORD: {
      return { ...state, loginNewPassword: true };
    }
    case user.TOGGLE_NEW_PASSWORD: {
      const { toggle } = action.payload;
      return {
        ...state,
        newPasswordToggle: toggle ? !state.newPasswordToggle : false
      };
    }
    case user.TOGGLE_FORGOT_PASSWORD: {
      const { toggle } = action.payload;

      return {
        ...state,
        forgotPasswordToggle: toggle ? !state.forgotPasswordToggle : false
      };
    }
    case user.GOTO_CHANGE_PASSWORD: {
      return { ...state, gotoChangePass: true };
    }
    case user.GOTO_RESET_PASSWORD: {
      return { ...state, gotoResetPass: true };
    }
    case user.RESET_PARAM: {
      let resetParam = {
        gotoHome: false,
        gotoLogin: false,
        gotoResetPass: false,
        gotoChangePass: false,
        gotoNewPass: false,
        newPasswordToggle: false,
        forgotPasswordToggle: false,
        loginNewPassword: false
      };
      return { ...state, ...resetParam };
    }
    case user.RESET_ALL_PARAM: {
      return { ...state, ...initialState };
    }
    case user.GOTO_HOME: {
      return { ...state, gotoHome: true };
    }
    default:
      return state;
  }
};
