import { item } from "../actionTypes";
import {
  array2String,
  array2StringExt
} from "../../helper";

const initialState = {
  tableItems: [],
  tokenObject: {},
  id: undefined,
  name: undefined,
  description: undefined,
  itemChoice: [],
  itemOption: [],
  itemType: [],

  createItemToggle: false,
  updateItemToggle: false,
  deleteItemToggle: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case item.LIST_ITEM: {
      const { tableItems, tokenObject, token } = action.payload;
      var newTableItems = tableItems;
      if (token != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return { ...state, tableItems: newTableItems, tokenObject };
    }
    case item.CREATE_ITEM: {
      const { result } = action.payload;

      var itemType = result.itemType.map(e => { return { type: e.type } })
      var itemTypeDisplay = array2String(itemType, null);
      var itemChoiceDisplay = array2StringExt(result.itemChoice, "name", "itemOption", "name")

      var newTableItems = [
        {
          id: result.id,
          name: result.name,
          description: result.description,
          price: result.price,
          itemType,
          itemTypeDisplay,
          itemChoice: result.itemChoice,
          itemChoiceDisplay,
          picture: result.picture
        },
        ...state.tableItems
      ];
      return { ...state, tableItems: newTableItems };
    }
    case item.UPDATE_ITEM: {
      const { result } = action.payload;

      var itemType = result.itemType.map(e => { return { type: e.type } })
      var itemTypeDisplay = array2String(itemType, null);
      var itemChoiceDisplay = array2StringExt(result.itemChoice, "name", "itemOption", "name")

      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.id;
      });
      newTableItems[index] = {
        id: result.id,
        name: result.name,
        description: result.description,
        price: result.price,
        itemType,
        itemTypeDisplay,
        itemChoice: result.itemChoice,
        itemChoiceDisplay,
        picture: result.picture
      };
      return { ...state, tableItems: newTableItems };
    }
    case item.DELETE_ITEM: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.id;
      });
      newTableItems.splice(index, 1);

      return { ...state, tableItems: newTableItems };
    }
    case item.TOGGLE_CREATE_ITEM: {
      const { toggle } = action.payload;
      return {
        ...state,
        createItemToggle: toggle ? !state.createItemToggle : false
      };
    }
    case item.TOGGLE_UPDATE_ITEM: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateItemToggle: toggle ? !state.updateItemToggle : false
      };
    }
    case item.TOGGLE_DELETE_ITEM: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteItemToggle: toggle ? !state.deleteItemToggle : false
      };
    }
    case item.RESET_ITEM_PARAMETERES: {
      return {
        ...state,
        id: undefined,
        name: undefined,
        description: undefined,
        itemChoice: [],
        itemOption: [],
        itemType: [],
        price: undefined,
        picture: undefined
      };
    }
    case item.UPDATE_ITEM_PARAMETERS: {
      const {
        id,
        name,
        description,
        price,
        itemType,
        itemChoice,
        picture,
        updateProps
      } = action.payload;
      const itemTypeArray = itemType.map(e => { return { type: e.type } })
      const itemChoiceArray = itemChoice.map(e => { return { name: e.name } })
      
      var itemOptionArray = []
      itemChoice.forEach((element, index) => {
        var itemOptionName = [];
        var itemOptionOption = [];
        element.itemOption.forEach((e, i) => {
          itemOptionName.push(e.name)
          itemOptionOption.push(e.option)
        })
        itemOptionArray[index] = {
          name: itemOptionName,
          option: itemOptionOption
        }
      })

      return {
        ...state,
        id,
        name,
        description,
        price,
        itemType: itemTypeArray,
        itemChoice: itemChoiceArray,
        itemOption: itemOptionArray,
        // picture
        updateProps
      };
    }
    case item.UPDATE_SINGLE_ITEM_PARAMETER: {
      const { key, value } = action.payload
      return { ...state, [key]: value }
    }
    case item.DELETE_ITEM_PARAMETERS: {
      const { id } = action.payload;
      return { ...state, id };
    }
    case item.HANDLE_CHANGE_ITEM: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    case item.HANDLE_CHANGE_ITEM_CHOICE: {
      const { key, value } = action.payload
      var itemChoice = [...state.itemChoice]
      itemChoice[key] = { name: value }
      return { ...state, itemChoice }
    }
    case item.HANDLE_CHANGE_ITEM_OPTION: {
      const { key, name, option } = action.payload
      var itemOption = [...state.itemOption]
      itemOption[key] = {
        name,
        option
      }
      return { ...state, itemOption }
    }
    case item.HANDLE_CHANGE_ITEM_TYPE: {
      const { type } = action.payload
      var itemType = []
      type.forEach(e => {
        itemType.push({
          type: e
        })
      })
      return { ...state, itemType }
    }
    default:
      return state;
  }
};
