import { rewardCode } from "../actionTypes";

const initialState = {
  tableItems: [],
  tokenObject: {},

  restaurantID: "allRestaurant",
  restaurantName: "All Restaurant",

  restaurantOptions: ""
};

export default (state = initialState, action) => {
  switch (action.type) {
    case rewardCode.LIST_REWARDCODE: {
      const { tableItems, tokenObject, token, search } = action.payload;
      var newTableItems = tableItems;
      if (token != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return { ...state, tableItems: newTableItems, tokenObject, search };
    }
    case rewardCode.PROCESS_OWNER_RESTAURANT_OPTIONS: {
      const { restaurantOptions } = action.payload;
      return {
        ...state,
        restaurantOptions
      };
    }
    case rewardCode.HANDLE_CHANGE_CHOOSE_REWARDCODE_OWNER_RESTAURANT: {
      const { restaurantID, restaurantName } = action.payload;
      return { ...state, restaurantID, restaurantName };
    }
    default:
      return state;
  }
};
