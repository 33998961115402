import { order } from "../actionTypes";

const initialState = {
  tableItems: [],
  tokenObject: {},
  id: undefined,
  numPending: 0,
  numCompleted: 0,
  numCancelled: 0,
  lineOrderData: [],
  restaurantID: "allRestaurant",
  view: "overview",

  restaurantOptions: "",

  displayOrderToggle: false,
  cancelOrderToggle: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case order.LIST_ORDER: {
      const { tableItems, tokenObject, token } = action.payload;
      var newTableItems = tableItems;
      if (token != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return { ...state, tableItems: newTableItems, tokenObject };
    }
    case order.LIST_ORDER_ANALYTICS: {
      return { ...state, ...action.payload }
    }
    case order.UPDATE_ORDER_STATUS: {
      const { result } = action.payload
      var newTableItems = [...state.tableItems]
      var index = newTableItems.findIndex(element => {
        return element.id == result.id
      })
      
      var newNumPending = state.numPending
      var newNumCompleted = state.numCompleted
      var newNumCancelled = state.numCancelled

      if (newTableItems[index].status != result.status) {
        switch (newTableItems[index].status) {
          case "PENDING":
            newNumPending -= 1
            break
          case "COMPLETED":
            newNumCompleted -= 1
            break
          case "CANCELLED":
            newNumCancelled -= 1
            break
          default:
            break
        }
        switch (result.status) {
          case "PENDING":
            newNumPending += 1
            break
          case "COMPLETED":
            newNumCompleted += 1
            break
          case "CANCELLED":
            newNumCancelled += 1
            break
          default:
            break
        }
      }
      newTableItems[index].status = result.status
      return { ...state, tableItems: newTableItems, numPending: newNumPending, numCompleted: newNumCompleted, numCancelled: newNumCancelled }
    }
    case order.TOGGLE_DISPLAY_ORDER: {
      const { toggle } = action.payload;
      return {
        ...state,
        displayOrderToggle: toggle ? !state.displayOrderToggle : false
      };
    }
    case order.TOGGLE_CANCEL_ORDER: {
      const { toggle } = action.payload
      return {
        ...state,
        cancelOrderToggle: toggle ? !state.cancelOrderToggle: false
      }
    }
    case order.DISPLAY_ORDER_PARAMETERS: {
      const {
        id,
        pubOrderID,
        datetime,
        tableNumber,
        restaurantName,
        menuName,
        customerName,
        orders
      } = action.payload;
      return {
        ...state,
        id,
        pubOrderID,
        datetime,
        tableNumber,
        restaurantName,
        menuName,
        customerName,
        orders
      };
    }
    case order.CANCEL_ORDER_PARAMETERS: {
      const { id, status } = action.payload
      return { ...state, id, status }
    }
    case order.PROCESS_OWNER_RESTAURANT_OPTIONS: {
      const { restaurantOptions } = action.payload;
      return {
        ...state,
        restaurantOptions
      };
    }
    case order.HANDLE_CHANGE_CHOOSE_ORDER_OWNER_RESTAURANT: {
      const { restaurantID } = action.payload;
      return { ...state, restaurantID };
    }
    case order.HANDLE_CHANGE_VIEW: {
      const { view } = action.payload
      return { ...state, view }
    }
    default:
      return state;
  }
};
