// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-1",
    "aws_appsync_graphqlEndpoint": "https://ykzr5jx27ja7beyo2w5f4rd77u.appsync-api.ap-southeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-4cmt2uh5iba5jazp7ri3wdn5bm",
    "aws_cognito_identity_pool_id": "ap-southeast-1:575498f5-1278-4ab5-9a6f-5a4a17a3bc67",
    "aws_cognito_region": "ap-southeast-1",
    "aws_user_pools_id": "ap-southeast-1_aRo5DanRp",
    "aws_user_pools_web_client_id": "18aqpo1ilv6lc4msdhit2dfpug",
    "oauth": {}
};


export default awsmobile;
