import { table } from "../actionTypes";

export default (state = {}, action) => {
  switch (action.type) {
    case table.SET_TABLE_PAGES: {
      const {
        allowNextPage,
        allowNextPageReplica,
        page,
        totalPages
      } = action.payload;
      return {
        allowNextPage,
        allowNextPageReplica,
        page,
        totalPages
      };
    }
    default:
      return state;
  }
};
