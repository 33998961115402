export const LIST_CUSTOMER = "LIST_CUSTOMER";
export const GET_CUSTOMER = "GET_CUSTOMER";
export const GET_CUSTOMER_ORDER = "GET_CUSTOMER_ORDER";
export const GET_CUSTOMER_POINT = "GET_CUSTOMER_POINT";
export const CREATE_CUSTOMER = "CREATE_CUSTOMER";
export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const UPDATE_POINT = "UPDATE_POINT"
export const REDEEM_REWARD = "REDEEM_REWARD"
export const USE_REWARD_CODE = "USE_REWARD_CODE"

export const TOGGLE_CREATE_CUSTOMER = "TOGGLE_CREATE_CUSTOMER"
export const TOGGLE_UPDATE_CUSTOMER = "TOGGLE_UPDATE_CUSTOMER";
export const TOGGLE_DELETE_CUSTOMER = "TOGGLE_DELETE_CUSTOMER";
export const TOGGLE_DISPLAY_CUSTOMER = "TOGGLE_DISPLAY_CUSTOMER";
export const TOGGLE_UPDATE_POINT = "TOGGLE_UPDATE_POINT"
export const TOGGLE_REDEEM_REWARD = "TOGGLE_REDEEM_REWARD"
export const TOGGLE_USE_REWARD_CODE = "TOGGLE_USE_REWARD_CODE"

export const RESET_CUSTOMER_PARAMETERES = "RESET_CUSTOMER_PARAMETERES";
export const DISPLAY_CUSTOMER_PARAMETERS = "DISPLAY_CUSTOMER_PARAMETERS";
export const UPDATE_CUSTOMER_PARAMETERS = "UPDATE_CUSTOMER_PARAMETERS";
export const DELETE_CUSTOMER_PARAMETERS = "DELETE_CUSTOMER_PARAMETERS";
export const UPDATE_POINT_PARAMETERS = "UPDATE_POINT_PARAMETERS"
export const REDEEM_REWARD_PARAMETERS = "REDEEM_REWARD_PARAMETERS"
export const USE_REWARD_CODE_PARAMETERS = "USE_REWARD_CODE_PARAMETERS"

export const PROCESS_OWNER_RESTAURANT_OPTIONS = "PROCESS_OWNER_RESTAURANT_OPTIONS";
export const PROCESS_REWARD_OPTIONS = "PROCESS_REWARD_OPTIONS"

export const HANDLE_CHANGE_CUSTOMER = "HANDLE_CHANGE_CUSTOMER";
export const HANDLE_CHANGE_CUSTOMER_RESTAURANT = "HANDLE_CHANGE_CUSTOMER_RESTAURANT";
export const HANDLE_CHANGE_CHOOSE_CUSTOMER_OWNER_RESTAURANT = "HANDLE_CHANGE_CHOOSE_CUSTOMER_OWNER_RESTAURANT";
export const HANDLE_CHANGE_CUSTOMER_REWARD = "HANDLE_CHANGE_CUSTOMER_REWARD"
export const HANDLE_CHANGE_CUSTOMER_POINT_TYPE = "HANDLE_CHANGE_CUSTOMER_POINT_TYPE"
