export const LOGIN = "LOGIN_USER";
export const FETCH_USER = "FETCH_USER";
export const LOGOUT = "LOGOUT_USER";
export const LOGIN_NEW_PASSWORD = "LOGIN_NEW_PASSWORD";

export const GOTO_HOME = "GOTO_HOME";
export const GOTO_LOGIN = "GOTO_LOGIN";
export const GOTO_RESET_PASSWORD = "GOTO_RESET_PASSWORD";
export const GOTO_CHANGE_PASSWORD = "GOTO_CHANGE_PASSWORD";
export const TOGGLE_NEW_PASSWORD = "TOGGLE_NEW_PASSWORD";
export const TOGGLE_FORGOT_PASSWORD = "TOGGLE_FORGOT_PASSWORD";
export const TOGGLE_CHANGE_PASSWORD = "TOGGLE_CHANGE_PASSWORD";

export const RESET_PARAM = "RESET_PARAM";
export const RESET_ALL_PARAM = "RESET_ALL_PARAM";