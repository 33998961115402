import React from "react";
import * as HttpStatus from "http-status-codes";
import cogoToast from "cogo-toast";
import CustomPhoneLib from "libphonenumber-js/custom";
import PhoneMeta from "libphonenumber-js/metadata.min.json";

const moment = require("moment");

const tableInitConfig = {
  loading: true,
  allowNextPage: false,
  page: 0,
  totalPages: 1,
  pageSize: 10,
  pageSizeOptions: [10, 50, 100],
  noDataText: "No Data",
  showPagination: false
};
const selectDDConfig = {
  maxItem: 10
};

const timeFormat = "HH:mm";
const timeFormatAWS = "HH:mmZ"
const dateFormat = "YYYY-MM-DD"
const timeDate = "1970-01-01T"
const timeDateEnd = ":00.000Z"
const timeFull = "T00:00:00.00Z"

const graphQLInputTimeFormat = (time) => {
  if (typeof time == "object") { 
    return timeDate + moment(time).format("HH") + ":" + moment(time).format("mm") + timeDateEnd
  } else {
    return timeDate + time + timeDateEnd;
  }
}

const graphQLInputDateFormat = (date) => {
  if (typeof date == "object") {
    return moment(date).format("YYYY-MM-DD") + timeFull;
  }
}

const graphQLOutputTimeFormat = (time) => {
  return time.split("T")[1].split(":")[0] + ":" + time.split("T")[1].split(":")[1]
}

const graphQLOutputDateFormat = (date) => {
  return date.split("T")[0] + " " + date.split("T")[1].split(".")[0];
}

const dateTimeFormat = (datetime, dateOnly=false) => {
  var date = datetime.split("T")[0];
  var time = [
    datetime.split("T")[1].split(":")[0],
    datetime.split("T")[1].split(":")[1],
    datetime.split("T")[1].split(":")[2].split(".")[0]
  ];
  if (time[2][2] == "+") {
    time[2] = time[2].split("+")[0];
  } else if (time[2][2] == "Z") {
    time[2] = time[2].split("Z")[0];
  }
  if (dateOnly) {
    return date
  } else {
    return date + " " + time[0] + ":" + time[1] + ":" + time[2];
  }
}

const array2String = (array, element, key="name") => {
  var string = ""
  array.map((e, index) => {
    if (element == null) {
      string += index == 0 ? `${e}` : `, ${e}`;
    } else {
      string += index == 0 ? `${e[element][key]}` : `, ${e[element][key]}`;
    }
  });
  return string;
}

const array2StringExt = (array, element, innerArray, innerArrayElement) => {
  var string = ""
  array.map((e, index) => {
    string += index == 0 ? `${e[element]}` : `, ${e[element]}`;
    e[innerArray].map((e2, index) => {
      string += index == 0 ? ` (${e2[innerArrayElement]}` : `, ${e2[innerArrayElement]}`
      if (e[innerArray].length == index + 1) {
        string += ")";
      }
    }) 
  });
  return string;
}

async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

const getRoute = (routes, pageName) => {
  return routes.default.filter(item => {
    return item.name === pageName;
  })[0];
};

const UserType = {
  POS: "POS",
  ADMIN: "ADMIN",
  SYSADMIN: "SYSADMIN"
}

const cnvModuleKeyType = {
  moduleID: "moduleID",
  moduleType: "moduleType",
  moduleYear: "moduleYear"
};

const cnvModuleKey = (moduleKey, type) => {
  let keys = moduleKey.split("_");
  let output = "";
  switch (type) {
    case cnvModuleKeyType.moduleID:
      output = keys[0];
      break;
    case cnvModuleKeyType.moduleType:
      output = keys[1];
      break;
    case cnvModuleKeyType.moduleYear:
      output = keys[2];
      break;
    default:
      output = "";
      break;
  }
  return output;
};

const loadingToastConf = {
  hideAfter: 0
};

const toastConf = {};

const dateTimePickerConf = {
  showTimeSelect: true,
  showMonthDropdown: true,
  placeholderText: "Latest",
  isClearable: true,
  timeFormat: "HH:mm",
  timeIntervals: 15,
  dateFormat: "d/MM/yyyy h:mm aa",
  timeCaption: "Time",
  todayButton: "Today"
};
const datePickerConf = {
  showMonthDropdown: true,
  placeholderText: "Select Date",
  dateFormat: "dd-MM-yyyy",
  todayButton: "Today"
};
const timePickerConf = {
  showTimeSelect: true,
  showTimeSelectOnly: true,
  placeholderText: "Select Time",
  timeIntervals: 15,
  dateFormat: "HH:mm",
  timeFormat: "HH:mm",
  timeCaption: "Time"
};
const reactTableConf = {
  className: "-striped -highlight",
  minRows: 5,
  resizable: false,
  defaultFilterMethod: (filter, row) =>
    String(row[filter.id])
      .toLowerCase()
      .includes(filter.value.toLowerCase())
};

const reactTableNumSort = (a, b) => {
  if (!isNaN(parseFloat(a)) && !isNaN(parseFloat(b))) {
    return parseFloat(a) - parseFloat(b);
  } else {
    return a > b;
  }
};

const selectFilterConf = {
  selectFilterMethod: (filter, row) => {
    if (filter.value === "all") {
      return true;
    } else {
      if (typeof row[filter.id] == "string") {
        return row[filter.id].toLowerCase() == filter.value.toLowerCase();
      } else {
        return row[filter.id] == filter.value;
      }
    }
  },

  genSelectWithOptions: (filter, onChange, options) => {
    let optionItems = options.map(item => {
      return (
        <option key={item.value} value={item.value}>
          {item.label}
        </option>
      );
    });
    return (
      <select
        onChange={event => onChange(event.target.value)}
        style={{width: "100%"}}
        value={filter ? filter.value : "all"}>
        <option value="all">All</option>
        {optionItems}
      </select>
    );
  }
};

const storageStr = {
  userType: "userType",
  userID: "userID",
  userPrivilege: "userPrivilege",
  universityID: "universityID"
};

function calculateStdTotal(totalConducted, constant, totalVoid, totalVoidP) {
  let voidValue = totalVoid || 0;
  let voidPValue = totalVoidP || 0;
  let modConst = constant || 0;
  if (modConst < totalConducted && modConst != 0) {
    return constant;
  } else {
    return totalConducted - voidValue + voidPValue;
  }
}

const attCalculation = {
  calculateAtt: (att, totalConducted, constant, totalVoid, totalVoidP) => {
    let finalAtt = 0;
    finalAtt = (att * 100) / calculateStdTotal(totalConducted, constant, totalVoid, totalVoidP);
    return finalAtt > 100 ? 100 : isNaN(finalAtt) ? 0 : finalAtt;
  },

  calculateStdTotal: (totalConducted, constant, totalVoid, totalVoidP) => {
    return calculateStdTotal(totalConducted, constant, totalVoid, totalVoidP);
  },

  calculateModTotal: (totalConducted, constant) => {
    let modConst = constant || 0;
    return modConst < totalConducted && modConst > 0 ? modConst : totalConducted;
  },

  calculateClassAtt: (studentsAttended, totalStudent) => {
    let finalAtt = 0;
    if (studentsAttended != null) {
      finalAtt = (studentsAttended * 100) / totalStudent;
    } else {
      finalAtt = 0;
    }
    //    console.log(finalAtt)
    return finalAtt > 100 ? 100 : isNaN(finalAtt) ? 0 : finalAtt;
  },

  calculateModAtt: (attList, classLGroup) => {
    let noLinkSum,
      avg = 0;
    let classLinkAtt = {};
    if (attList.length) {
      let nolinkClass = attList.filter(function(classes) {
        return classes.classLinkID == null;
      });
      noLinkSum = attList.reduce(function(a, b) {
        // console.log(a, b)
        if (b.classLinkID != null) {
          if (classLinkAtt[b.classLinkID] != null) {
            if (classLinkAtt[b.classLinkID] > 100) {
              classLinkAtt[b.classLinkID] = 100;
            } else {
              classLinkAtt[b.classLinkID] += b.calAttRate;
            }
          } else {
            classLinkAtt[b.classLinkID] = b.calAttRate;
          }
          return a;
        } else {
          return a + b.calAttRate;
        }
      }, 0);
      //        console.log(classLinkAtt)
      let linkSum = Object.keys(classLinkAtt).reduce(function(a, b) {
        return a + classLinkAtt[b];
      }, 0);
      let linkClassCount = Object.keys(classLGroup).length || 0;
      //        console.log(linkSum + noLinkSum, (linkClassCount + nolinkClass.length))
      avg = (noLinkSum + linkSum) / (linkClassCount + nolinkClass.length);
    }
    //    console.log(avg, sum, classLGroup, nolinkClass.length)
    return avg > 100 ? 100 : isNaN(avg) ? 0 : avg;
  }
};

function modGroupToStr(moduleGroup) {
  return String.fromCharCode(moduleGroup + 65);
}

function classGroupToStr(classGroup) {
  return `Grp ${classGroup + 1}`;
}

const groupLabeling = {
  classModGroupToStr: (moduleGroup, classGroup) => {
    return `Set ${modGroupToStr(moduleGroup)} ${classGroupToStr(classGroup)}`;
  },
  modGroupToStr: modGroupToStr,
  classGroupToStr: classGroupToStr
};

// function processResult(res, showSuccess) {
//   if (res != undefined) {
//     const resData = {
//       body: res.data.body,
//       statusCode: res.data.statusCode,
//       message: res.data.message
//     };
//     // console.log(resData);
//     switch (resData.statusCode) {
//       case HttpStatus.OK:
//         if (showSuccess) {
//           cogoToast.success("Successfully processed", toastConf);
//         }
//         return true;
//       default:
//         cogoToast.error(JSON.stringify(resData.body), toastConf);
//         return false;
//     }
//   } else {
//     return false;
//   }
// }

function processResult(data, message) {
  if (data) {
    var res = {
      body: "Result Body",
      statusCode: 200,
      message: HttpStatus.getStatusText(200)
    };
    var status = 1;
  } else {
    var res = {
      body: message ? message : "Error Body",
      statusCode: 420,
      message: HttpStatus.getStatusText(420)
    };
    var status = 1;
  }

  console.log(res);
  if (res != undefined && status == 1) {
    const resData = {
      body: res.body,
      statusCode: res.statusCode,
      message: res.message
    };
    console.log(resData);
    switch (resData.statusCode) {
      case HttpStatus.OK:
        cogoToast.success(resData.body, toastConf);
        return true;
      default:
        cogoToast.error(JSON.stringify(resData.body), toastConf);
        return false;
    }
  } else if (res != undefined && status == 0) {
    const resData = {
      body: "Unable to connect to server. It appears you are currently offline",
      statusCode: HttpStatus.METHOD_FAILURE,
      message: "Method Failure"
    };
    console.log(resData);
    cogoToast.error(JSON.stringify(resData.body), toastConf);
    return false;
  } else {
    return false;
  }
}

const datesAreOnSameDay = (first, second) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

function getMinutes(startDateTime, endDateTime) {
  var dateArray = [];
  var startDateTime = moment(startDateTime).subtract(30, "minutes");
  var endDateTime = moment(endDateTime).add(30, "minutes");
  while (startDateTime <= endDateTime) {
    dateArray.push(moment(startDateTime).toISOString());
    startDateTime = moment(startDateTime).add(1, "minutes");
  }
  return dateArray;
}

function getMinutesSides(datetime) {
  Object.keys(datetime).forEach(key => {
    var prevDatetime = moment(key).subtract(1, "minutes").toISOString()
    var nextDatetime = moment(key).add(1, "minutes").toISOString()

    if (!datetime.includes(prevDatetime)) {
      datetime[prevDatetime] = 0
    }
    if (!datetime.includes(nextDatetime)) {
      datetime[nextDatetime] = 0
    }
  })
  var dateSortedArray = Object.keys(datetime)
    .sort()
    .map(function (key) {
      return key;
    });
  return { datetime, dateSortedArray }
}

function removeSeconds(datetime) {
  return moment(datetime).seconds(0).milliseconds(0).toISOString()
}

function formatPhoneNumber(phoneNumber){
  return CustomPhoneLib.format(phoneNumber, "MY", "E.164", PhoneMeta)
} 
function isValidPhoneNumber(...args){
  return CustomPhoneLib.isValidNumber(...args, PhoneMeta)
}
  

export {
  getRoute,
  UserType,
  tableInitConfig,
  cnvModuleKeyType,
  cnvModuleKey,
  selectDDConfig,
  timeFormat,
  timeFormatAWS,
  dateFormat,
  timeDate,
  timeDateEnd,
  graphQLInputTimeFormat,
  graphQLInputDateFormat,
  graphQLOutputTimeFormat,
  graphQLOutputDateFormat,
  dateTimeFormat,
  array2String,
  array2StringExt,
  asyncForEach,
  HttpStatus,
  toastConf,
  loadingToastConf,
  dateTimePickerConf,
  datePickerConf,
  timePickerConf,
  reactTableConf,
  reactTableNumSort,
  selectFilterConf,
  storageStr,
  attCalculation,
  processResult,
  groupLabeling,
  datesAreOnSameDay,
  getMinutes,
  getMinutesSides,
  removeSeconds,
  formatPhoneNumber,
  isValidPhoneNumber
};
