module.exports = {
  helper: require("./helper"),
  owner: require("./owner"),
  restaurant: require("./restaurant"),
  menu: require("./menu"),
  item: require("./item"),
  customer: require("./customer"),
  order: require("./order"),
  table: require("./table"),
  user: require("./user"),
  reward: require("./reward"),
  rewardCode: require("./rewardCode")
};