export const LIST_REWARD = "LIST_REWARD";
export const GET_REWARD = "GET_REWARD";
export const CREATE_REWARD = "CREATE_REWARD";
export const UPDATE_REWARD = "UPDATE_REWARD";
export const DELETE_REWARD = "DELETE_REWARD";

export const TOGGLE_CREATE_REWARD = "TOGGLE_CREATE_REWARD";
export const TOGGLE_UPDATE_REWARD = "TOGGLE_UPDATE_REWARD";
export const TOGGLE_DELETE_REWARD = "TOGGLE_DELETE_REWARD";

export const RESET_REWARD_PARAMETERES = "RESET_REWARD_PARAMETERES";
export const UPDATE_REWARD_PARAMETERS = "UPDATE_REWARD_PARAMETERS";
export const DELETE_REWARD_PARAMETERS = "DELETE_REWARD_PARAMETERS";

export const PROCESS_OWNER_RESTAURANT_OPTIONS = "PROCESS_OWNER_RESTAURANT_OPTIONS";

export const HANDLE_CHANGE_REWARD = "HANDLE_CHANGE_REWARD";
export const HANDLE_CHANGE_REWARD_RESTAURANT = "HANDLE_CHANGE_REWARD_RESTAURANT";
export const HANDLE_CHANGE_CHOOSE_REWARD_OWNER_RESTAURANT = "HANDLE_CHANGE_CHOOSE_REWARD_OWNER_RESTAURANT";