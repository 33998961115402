import { restaurant } from "../actionTypes";
import { graphQLOutputTimeFormat } from "../../helper";

const initialState = {
  tableItems: [],
  tokenObject: {},
  id: undefined,
  name: undefined,
  description: undefined,
  location: undefined,
  openTime: undefined,
  closeTime: undefined,
  ownerID: undefined,
  restaurantInfo: {},

  restaurantOptions: "",
  restaurantOptionsRequestDone: false,

  createRestaurantToggle: false,
  updateRestaurantToggle: false,
  deleteRestaurantToggle: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case restaurant.LIST_RESTAURANT: {
      const { tableItems, tokenObject, token } = action.payload;
      var newTableItems = tableItems;
      if (token != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return { ...state, tableItems: newTableItems, tokenObject };
    }
    case restaurant.GET_RESTAURANT: {
      const { name, description, location, openTime, closeTime, ownerRestaurantID, ownerID, ownerName, ownerNameDisplay } = action.payload;

      var openTimeFormatted = graphQLOutputTimeFormat(openTime);
      var closeTimeFormatted = graphQLOutputTimeFormat(closeTime);

      var restaurantInfo = {
        name,
        description,
        location,
        openTime: openTimeFormatted,
        closeTime: closeTimeFormatted,
        ownerRestaurantID,
        ownerID,
        ownerName,
        ownerNameDisplay
      };

      return { ...state, restaurantInfo };
    }
    case restaurant.CREATE_RESTAURANT: {
      const { result } = action.payload;

      var openTime = graphQLOutputTimeFormat(result.openTime);
      var closeTime = graphQLOutputTimeFormat(result.closeTime);

      var time = openTime + " - " + closeTime
      var newTableItems = [
        {
          id: result.id,
          name: result.name,
          description: result.description,
          location: result.location,
          openTime,
          closeTime,
          time,
          ownerRestaurantID: result.ownerRestaurantID ? result.ownerRestaurantID : null,
          ownerID: result.ownerID ? result.ownerID : null,
          ownerName: result.ownerName ? result.ownerName : null,
          ownerNameDisplay: result.ownerNameDisplay ? result.ownerNameDisplay : null,
        },
        ...state.tableItems
      ];
      return { ...state, tableItems: newTableItems };
    }
    case restaurant.UPDATE_RESTAURANT: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.id;
      });
      var openTime = graphQLOutputTimeFormat(result.openTime);
      var closeTime = graphQLOutputTimeFormat(result.closeTime);
      var time = openTime + " - " + closeTime
      newTableItems[index] = {
        id: result.id,
        name: result.name,
        description: result.description,
        location: result.location,
        openTime,
        closeTime,
        time,
        ownerRestaurantID: result.ownerRestaurantID ? result.ownerRestaurantID : null,
        ownerID: result.ownerID ? result.ownerID : null,
        ownerName: result.ownerName ? result.ownerName : null,
        ownerNameDisplay: result.ownerNameDisplay ? result.ownerNameDisplay : null
      };
      var restaurantInfo = {
        name: result.name,
        description: result.description,
        location: result.location,
        openTime,
        closeTime,
        ownerRestaurantID: result.ownerRestaurantID ? result.ownerRestaurantID : null,
        ownerID: result.ownerID ? result.ownerID : null,
        ownerName: result.ownerName ? result.ownerName : null,
        ownerNameDisplay: result.ownerNameDisplay ? result.ownerNameDisplay : null
      };
      return { ...state, tableItems: newTableItems, restaurantInfo };
    }
    case restaurant.DELETE_RESTAURANT: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.id;
      });
      newTableItems.splice(index, 1);

      return { ...state, tableItems: newTableItems };
    }
    case restaurant.TOGGLE_CREATE_RESTAURANT: {
      const { toggle } = action.payload;
      return {
        ...state,
        createRestaurantToggle: toggle ? !state.createRestaurantToggle : false
      };
    }
    case restaurant.TOGGLE_UPDATE_RESTAURANT: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateRestaurantToggle: toggle ? !state.updateRestaurantToggle : false
      };
    }
    case restaurant.TOGGLE_DELETE_RESTAURANT: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteRestaurantToggle: toggle ? !state.deleteRestaurantToggle : false
      };
    }
    case restaurant.RESET_RESTAURANT_PARAMETERES: {
      return {
        ...state,
        id: undefined,
        name: undefined,
        description: undefined,
        location: undefined,
        openTime: undefined,
        closeTime: undefined
      };
    }
    case restaurant.UPDATE_RESTAURANT_PARAMETERS: {
      const {
        id,
        name,
        description,
        location,
        openTime,
        closeTime,
        ownerRestaurantID,
        ownerID,
        ownerName
      } = action.payload;
      return {
        ...state,
        id,
        name,
        description,
        location,
        openTime,
        closeTime,
        ownerRestaurantID,
        ownerIDPrev: ownerID,
        ownerID,
        ownerNamePrev: ownerName,
        ownerName
      };
    }
    case restaurant.DELETE_RESTAURANT_PARAMETERS: {
      const { id, ownerRestaurantID } = action.payload;
      return { ...state, id, ownerRestaurantID };
    }
    case restaurant.PROCESS_RESTAURANT_OPTIONS: {
      const { restaurantOptions } = action.payload
      return { ...state, restaurantOptions, restaurantOptionsRequestDone: true };
    }
    case restaurant.HANDLE_CHANGE_ADD_RESTAURANT_OWNER: {
      const { ownerID } = action.payload;
      return { ...state, ownerID };
    }
    case restaurant.HANDLE_CHANGE_RESTAURANT: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    default:
      return state;
  }
};
