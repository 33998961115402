export const LIST_OWNER = "LIST_OWNER";
export const CREATE_OWNER = "CREATE_OWNER";
export const UPDATE_OWNER = "UPDATE_OWNER";
export const DELETE_OWNER = "DELETE_OWNER";

export const TOGGLE_CREATE_OWNER = "TOGGLE_CREATE_OWNER"
export const TOGGLE_UPDATE_OWNER = "TOGGLE_UPDATE_OWNER";
export const TOGGLE_DELETE_OWNER = "TOGGLE_DELETE_OWNER";

export const RESET_OWNER_PARAMETERES = "RESET_OWNER_PARAMETERES";
export const UPDATE_OWNER_PARAMETERS = "UPDATE_OWNER_PARAMETERS";
export const DELETE_OWNER_PARAMETERS = "DELETE_OWNER_PARAMETERS";

export const PROCESS_USERTYPE_OPTIONS = "PROCESS_USERTYPE_OPTIONS";
export const PROCESS_OWNER_OPTIONS = "PROCESS_OWNER_OPTIONS"

export const HANDLE_CHANGE_ADD_OWNER_RESTAURANT = "HANDLE_CHANGE_ADD_OWNER_RESTAURANT";
export const HANDLE_CHANGE_ADD_OWNER_USERTYPE = "HANDLE_CHANGE_ADD_OWNER_USERTYPE"

export const HANDLE_CHANGE_OWNER = "HANDLE_CHANGE_OWNER"
