export const LIST_ORDER = "LIST_ORDER";
export const LIST_ORDER_ANALYTICS = "LIST_ORDER_ANALYTICS"
export const DISPLAY_ORDER = "DISPLAY_ORDER";
export const UPDATE_ORDER_STATUS = "UPDATE_ORDER_STATUS"

export const TOGGLE_DISPLAY_ORDER = "TOGGLE_DISPLAY_ORDER";
export const TOGGLE_CANCEL_ORDER = "TOGGLE_CANCEL_ORDER"

export const DISPLAY_ORDER_PARAMETERS = "DISPLAY_ORDER_PARAMETERS";
export const CANCEL_ORDER_PARAMETERS = "CANCEL_ORDER_PARAMETERS"

export const PROCESS_OWNER_RESTAURANT_OPTIONS = "PROCESS_OWNER_RESTAURANT_OPTIONS";

export const HANDLE_CHANGE_ORDER = "HANDLE_CHANGE_ORDER";
export const HANDLE_CHANGE_CHOOSE_ORDER_OWNER_RESTAURANT = "HANDLE_CHANGE_CHOOSE_ORDER_OWNER_RESTAURANT";
export const HANDLE_CHANGE_VIEW = "HANDLE_CHANGE_VIEW";
