export const LIST_RESTAURANT = "LIST_RESTAURANT";
export const GET_RESTAURANT = "GET_RESTAURANT";
export const CREATE_RESTAURANT = "CREATE_RESTAURANT";
export const UPDATE_RESTAURANT = "UPDATE_RESTAURANT";
export const DELETE_RESTAURANT = "DELETE_RESTAURANT";

export const TOGGLE_CREATE_RESTAURANT = "TOGGLE_CREATE_RESTAURANT";
export const TOGGLE_UPDATE_RESTAURANT = "TOGGLE_UPDATE_RESTAURANT";
export const TOGGLE_DELETE_RESTAURANT = "TOGGLE_DELETE_RESTAURANT";

export const RESET_RESTAURANT_PARAMETERES = "RESET_RESTAURANT_PARAMETERES";
export const UPDATE_RESTAURANT_PARAMETERS = "UPDATE_RESTAURANT_PARAMETERS";
export const DELETE_RESTAURANT_PARAMETERS = "DELETE_RESTAURANT_PARAMETERS";

export const PROCESS_RESTAURANT_OPTIONS = "PROCESS_RESTAURANT_OPTIONS";

export const HANDLE_CHANGE_RESTAURANT = "HANDLE_CHANGE_RESTAURANT";
export const HANDLE_CHANGE_ADD_RESTAURANT_OWNER = "HANDLE_CHANGE_ADD_RESTAURANT_OWNER";
