import { customer } from "../actionTypes";

const initialState = {
  tableItems: [],
  tokenObject: {},
  tableItemsOrders: [],
  orderTokenObject: {},
  tableItemsPoints: [],
  pointTokenObject: {},
  id: undefined,
  name: undefined,
  phoneNumber: undefined,
  orders: undefined,
  points: undefined,
  totalPoints: undefined,
  customerRestaurantID: undefined,
  restaurantName: undefined,
  amount: undefined,
  totalAmount: undefined,
  updatePointState: false,
  type: "EARNED",

  restaurantID: "allRestaurant",
  restaurantName: "All Restaurant",

  restaurantOptions: "",
  rewardOptions: "",

  createCustomerToggle: false,
  updateCustomerToggle: false,
  deleteCustomerToggle: false,
  displayCustomerToggle: false,
  updatePointToggle: false,
  redeemRewardToggle: false,
  useRewardCodeToggle: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case customer.LIST_CUSTOMER: {
      const { tableItems, tokenObject, token, search } = action.payload;
      var newTableItems = tableItems;
      if (token != undefined) {
        newTableItems = state.tableItems.concat(tableItems);
      }
      return { ...state, tableItems: newTableItems, tokenObject, search };
    }
    case customer.GET_CUSTOMER: {
      const {
        id,
        name,
        phoneNumber,
        totalPoints,
        tableItemsOrders,
        tableItemsPoints
      } = action.payload;
      return {
        ...state,
        id,
        name,
        phoneNumber,
        totalPoints,
        tableItemsOrders,
        tableItemsPoints
      };
    }
    case customer.GET_CUSTOMER_ORDER: {
      const { tableItemsOrders, orderTokenObject, token } = action.payload;
      var newTableItems = tableItemsOrders;
      if (token != undefined) {
        newTableItems = state.tableItemsOrders.concat(tableItemsOrders);
      }
      return { ...state, tableItemsOrders: newTableItems, orderTokenObject };
    }
    case customer.GET_CUSTOMER_POINT: {
      const { tableItemsPoints, pointTokenObject, token } = action.payload;
      var newTableItems = tableItemsPoints;
      if (token != undefined) {
        newTableItems = state.tableItemsPoints.concat(tableItemsPoints);
      }
      return { ...state, tableItemsPoints: newTableItems, pointTokenObject };
    }
    case customer.CREATE_CUSTOMER: {
      const { result } = action.payload;
      var newTableItems = [
        {
          id: result.id,
          name: result.name,
          phoneNumber: result.phoneNumber
        },
        ...state.tableItems
      ];
      return { ...state, tableItems: newTableItems };
    }
    case customer.UPDATE_CUSTOMER: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.id;
      });
      newTableItems[index] = {
        id: result.id,
        name: result.name,
        phoneNumber: result.phoneNumber
      };
      return { ...state, tableItems: newTableItems };
    }
    case customer.DELETE_CUSTOMER: {
      const { result } = action.payload;

      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.id;
      });
      newTableItems.splice(index, 1);

      return { ...state, tableItems: newTableItems };
    }
    case customer.UPDATE_POINT: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      console.log("TCL: newTableItems", newTableItems);
      if (state.updatePointState == true) {
        var index = newTableItems.findIndex(element => {
          return element.customerTotalPointId == result.id;
        });
        newTableItems[index].totalAmount = result.totalAmount;
      } else {
        var newTableItems = [
          {
            id: result.customer.id,
            name: result.customer.name,
            phoneNumber: result.customer.phoneNumber,
            totalAmount: result.totalAmount,
            customerTotalPointId: result.id,
            customerRestaurantId: result.restaurant.id,
            restaurantName: result.restaurant.name
          },
          ...state.tableItems
        ];
      }
      return { ...state, tableItems: newTableItems };
    }
    case customer.REDEEM_REWARD: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      // var index = newTableItems.findIndex(element => {
      //   return element.id == result.id
      // })
      // newTableItems[items] = {
      //   id: result.id,
      //   name: result.name,
      //   phoneNumber: result.phoneNumber,
      //   totalPoints: result.totalPoints
      // }
      return { ...state, tableItems: newTableItems };
    }
    case customer.USE_REWARD_CODE: {
      const { result } = action.payload;
      var newTableItems = [...state.tableItems];
      var index = newTableItems.findIndex(element => {
        return element.id == result.rewardCodeCustomerId;
      });
      newTableItems[index].totalAmount = result.totalAmount;
      return { ...state, tableItems: newTableItems };
    }
    case customer.TOGGLE_CREATE_CUSTOMER: {
      const { toggle } = action.payload;
      return {
        ...state,
        createCustomerToggle: toggle ? !state.createCustomerToggle : false
      };
    }
    case customer.TOGGLE_UPDATE_CUSTOMER: {
      const { toggle } = action.payload;
      return {
        ...state,
        updateCustomerToggle: toggle ? !state.updateCustomerToggle : false
      };
    }
    case customer.TOGGLE_DELETE_CUSTOMER: {
      const { toggle } = action.payload;
      return {
        ...state,
        deleteCustomerToggle: toggle ? !state.deleteCustomerToggle : false
      };
    }
    case customer.TOGGLE_DISPLAY_CUSTOMER: {
      const { toggle } = action.payload;
      return {
        ...state,
        displayCustomerToggle: toggle ? !state.displayCustomerToggle : false
      };
    }
    case customer.TOGGLE_UPDATE_POINT: {
      const { toggle } = action.payload;
      return {
        ...state,
        updatePointToggle: toggle ? !state.updatePointToggle : false
      };
    }
    case customer.TOGGLE_REDEEM_REWARD: {
      const { toggle } = action.payload;
      return {
        ...state,
        redeemRewardToggle: toggle ? !state.redeemRewardToggle : false
      };
    }
    case customer.TOGGLE_USE_REWARD_CODE: {
      const { toggle } = action.payload;
      return {
        ...state,
        useRewardCodeToggle: toggle ? !state.useRewardCodeToggle : false
      };
    }
    case customer.RESET_CUSTOMER_PARAMETERES: {
      return {
        ...state,
        id: undefined,
        name: undefined,
        phoneNumber: undefined
      };
    }
    case customer.DISPLAY_CUSTOMER_PARAMETERS: {
      const { id, name, phoneNumber, totalPoints } = action.payload;
      return { ...state, id, name, phoneNumber, totalPoints };
    }
    case customer.UPDATE_CUSTOMER_PARAMETERS: {
      const { id, name, phoneNumber } = action.payload;
      return { ...state, id, name, phoneNumber };
    }
    case customer.DELETE_CUSTOMER_PARAMETERS: {
      const { id } = action.payload;
      return { ...state, id };
    }
    case customer.UPDATE_POINT_PARAMETERS: {
      const {
        id,
        phoneNumber,
        totalAmount,
        customerRestaurantID,
        restaurantName,
        updatePointState
      } = action.payload;
      return {
        ...state,
        id,
        phoneNumber,
        totalAmount,
        customerRestaurantID,
        restaurantName,
        updatePointState
      };
    }
    case customer.REDEEM_REWARD_PARAMETERS: {
      const {
        rewardCustomerId,
        rewardRestaurantId,
        restaurantName,
        totalAmount
      } = action.payload;
      return {
        ...state,
        rewardCustomerId,
        rewardRestaurantId,
        restaurantName,
        totalAmount
      };
    }
    case customer.USE_REWARD_CODE_PARAMETERS: {
      const {
        rewardCodeRestaurantId,
        restaurantName,
        rewardCodeCustomerId
      } = action.payload;
      return {
        ...state,
        rewardCodeRestaurantId,
        restaurantName,
        rewardCodeCustomerId
      };
    }
    case customer.PROCESS_OWNER_RESTAURANT_OPTIONS: {
      const { restaurantOptions } = action.payload;
      return {
        ...state,
        restaurantOptions
      };
    }
    case customer.PROCESS_REWARD_OPTIONS: {
      const { rewardOptions } = action.payload;
      return { ...state, rewardOptions };
    }
    case customer.HANDLE_CHANGE_CUSTOMER: {
      const { key, value } = action.payload;
      return { ...state, [key]: value };
    }
    case customer.HANDLE_CHANGE_CUSTOMER_RESTAURANT: {
      const { customerRestaurantID } = action.payload;
      return { ...state, customerRestaurantID };
    }
    case customer.HANDLE_CHANGE_CHOOSE_CUSTOMER_OWNER_RESTAURANT: {
      const { restaurantID, restaurantName } = action.payload;
      return { ...state, restaurantID, restaurantName };
    }
    case customer.HANDLE_CHANGE_CUSTOMER_REWARD: {
      const { rewardId } = action.payload;
      return { ...state, rewardId };
    }
    case customer.HANDLE_CHANGE_CUSTOMER_POINT_TYPE: {
      const { type } = action.payload;
      return { ...state, type };
    }
    default:
      return state;
  }
};
